import React from 'react';

import UNSANA1 from "../assets/UNSANA1.png";
import UNSANA2 from "../assets/UNSANA2.png";
import "../styles/HomeContent.scss";

const HomeContent = () => {
  return (
    <div className='home-content'>
      <h2>
        UNSANA
      </h2>
      <a href="https://www.unsana.shop/" target='_blank' rel="noopener noreferrer">
        <div className='home-content__img-container'>
          <img src={UNSANA2} className='' alt="UNSANA" />
        </div>
      </a>
    </div>
  )
}

export default HomeContent;