import React, { useState, useEffect } from 'react';
import "../styles/NavTwo.scss";

function NavTwo() {
  const [shrink, setShrink] = useState(false);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        setShrink(true);
      } else {
        setShrink(false);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav className={shrink ? 'shrink' : ''}>
      {/* Navigation content */}
      EA
    </nav>
  );
}

// function NavTwo() {

// }

export default NavTwo;