import React from 'react'
import "./SkillApp.scss";

import imageUrl from "../assets/icons/mongodb.svg"
import JS from "../assets/icons/javascript.svg"
import HTML from "../assets/icons/html.svg"
import MUI from "../assets/icons/mui.svg"
import ReactIcon from "../assets/icons/react.svg"
import SASS from "../assets/icons/sass.svg"
import TailWindCSS from "../assets/icons/tailwindcss.svg"
import CSS from "../assets/icons/css.svg"
import SHOPIFFY from "../assets/icons/SHOPIFY.png"
import SHOP from "../assets/icons/shopify.svg"
import { meta, shopify, starbucks, tesla} from "../assets/images";


const SkillApp = () => {
  return (
    <div className='skill_application'>
      <div className="container">
        {/* USE THIS FUNCTION TO MINIMIZE CODE */}
        {/* {skills.map((skill) => (
            <img src={skill.imageUrl} />
        ))} */}
        <div className="card">
          <div className="imgBx">
            <img src={MUI} />
          </div>
          <div className="content">
            <h2>MUI</h2>
            {/* <p>ABOUT.</p> */}
          </div>
        </div>
        <div className="card">
          <div className="imgBx">
            <img src={HTML} />
          </div>
          <div className="content">
            <h2>HTML</h2>
            {/* <p>ABOUT.</p> */}
          </div>
        </div>
        <div className="card">
          <div className="imgBx">
            <img src={CSS} />
          </div>
          <div className="content">
            <h2>CSS</h2>
            {/* <p>ABOUT.</p> */}
          </div>
        </div>
        <div className="card">
          <div className="imgBx">
            <img src={JS} />
          </div>
          <div className="content">
            <h2>JS</h2>
            {/* <p>ABOUT.</p> */}
          </div>
        </div>
        <div className="card">
          <div className="imgBx">
            <img src={SASS} />
          </div>
          <div className="content">
            <h2>SASS</h2>
            {/* <p>ABOUT.</p> */}
          </div>
        </div>
        <div className="card">
          <div className="imgBx">
            <img src={TailWindCSS} />
          </div>
          <div className="content">
            <h2>TWCSS</h2>
            {/* <p>ABOUT.</p> */}
          </div>
        </div>
        <div className="card">
          <div className="imgBx">
            <img src={ReactIcon} />
          </div>
          <div className="content">
            <h2>React</h2>
            {/* <p>ABOUT.</p> */}
          </div>
        </div>
        <div className="card">
          <div className="imgBx">
            <img src={SHOPIFFY} />
          </div>
          <div className="content">
            <h2>Shopify</h2>
            {/* <p>ABOUT.</p> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SkillApp