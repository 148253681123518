import { meta, shopify, starbucks, tesla} from "../assets/images";
import BlazedKid from "../assets/images/BlazedKid.png";
import PreBuild from "../assets/images/PreBuild.png";
import Crypto from "../assets/images/Crypto.png";
import JDMLover from "../assets/images/JDMLover.png";
import WEBUNSANA from "../assets/images/WEBUNSANA.png";

import {
    car,
    contact,
    css,
    estate,
    git,
    github,
    html,
    javascript,
    linkedin,
    mongodb,
    mui,
    nextjs,
    nodejs,
    pricewise,
    react,
    sass,
    snapgram,
    summiz,
    tailwindcss,
    threads,
} from "../assets/icons";

export const skills = [
    {
        imageUrl: html,
        name: "HTML",
        type: "Frontend",
    },
    {
        imageUrl: css,
        name: "CSS",
        type: "Frontend",
    },
    // {
    //     imageUrl: git,
    //     name: "Git",
    //     type: "Version Control",
    // },
    {
        imageUrl: javascript,
        name: "JavaScript",
        type: "Frontend",
    },
    {
        imageUrl: github,
        name: "GitHub",
        type: "Version Control",
    },
    {
        imageUrl: mongodb,
        name: "MongoDB",
        type: "Database",
    },
    {
        imageUrl: mui,
        name: "Material-UI",
        type: "Frontend",
    },
    // {
    //     imageUrl: nextjs,
    //     name: "Next.js",
    //     type: "Frontend",
    // },
    // {
    //     imageUrl: nodejs,
    //     name: "Node.js",
    //     type: "Backend",
    // },
    {
        imageUrl: react,
        name: "React",
        type: "Frontend",
    },
    {
        imageUrl: sass,
        name: "Sass",
        type: "Frontend",
    },
    {
        imageUrl: tailwindcss,
        name: "Tailwind CSS",
        type: "Frontend",
    },
    {
        imageUrl: shopify,
        name: "Shopify",
        type: "E-Commerce",
    },
];

export const experiences = [
    {
        title: "Meat Clerk",
        company_name: "Super Fresh Market",
        iconBg: "#accbe1",
        date: "June 2013 - Aug 2014",
        points: [
            "Identifeid spoilage and damaged merchandise for proper disposal.",
            "Received and processed shipments of incoming merchandise.",
            "Ensured proper placement and product ratation of incoming inventory.",
            "Monitored and replineshed merchandise as needed.",
            "Maintained area beautification to create an enchanced shopping exprience.",
        ],
    },
    {
        title: "Associate",
        company_name: "Likewize",
        // icon: tesla,
        iconBg: "#fbc3bc",
        date: "Oct 2016 - Feb 2018",
        points: [
            "Record shipment data such tracking packaged compliance, damages or discrepancies for accounting and record-keeping purposes.",
            "Processed returns by serial number using a PC based wordstation that consisted of Likewize sotfware.",
            "Organized devices by port number and valued devices by qaulity",
        ],
    },
    {
        title: "Merchandiser",
        company_name: "ThredUp",
        // icon: shopify,
        iconBg: "#b7e4c7",
        date: "Jan 2018 - Jan 2021",
        points: [
            "Assist in a company initiave / demonstrating sportsmanship, thoughtful solving, communicatin and recapping.",
            "Responsible for auditing inventory based attributes including gender and season.",
            "Strong attention to detail for item accuracy and qaulity.",
            "Styled and photographed clothing, in order to ensure an overall elevate site aesthetic.",
        ],
    },
    {
        title: "Custom PC Builder",
        company_name: "Lyte Technology",
        // icon: shopify,
        iconBg: "#b7e4c7",
        date: "Apr 2023 - May 2023",
        points: [
            "Performed upgrades, repairs test and cleaned desktop compputer systems.",
            "Budgeted, Ordered and Built computer systems from parts for various clientele.",
            "Configured and built computer systems to client specifications and budget Related Projects.",
            "Served as a computer technician both testing and assembling many varieties of computers assemble custom, small form factor computer systems.",
            "Inspected and diagnosed wiring installation and issues to maintain safety.",
        ],
    },
    {
        title: "Desktop Support Tech",
        company_name: "CDW",
        // icon: meta,
        iconBg: "#a2d2ff",
        date: "Oct 2023 - Present",
        points: [
            "Resolved service request by individually troubleshoting and addressing user issues. ",
            "Lent technical support, consulting and implementing services to maintain system performance thresholds.",
            "Evaluated software testing, deployment and updates to maintain complaince with organization mandates.",
            "Troubleshot desktop and notebook issues with clients and CDW employees.",
            "Reviewed current hardware and softwre configurations and recommended modifations to increase system speed.",
        ],
    },
];

export const socialLinks = [
    {
        name: 'Contact',
        iconUrl: contact,
        link: '/contact',
    },
    {
        name: 'GitHub',
        iconUrl: github,
        link: 'https://github.com/estelo2jz',
    },
    {
        name: 'LinkedIn',
        iconUrl: linkedin,
        link: 'https://www.linkedin.com/in/estelo-abellanosa-83a335186',
    }
];

export const projects = [
    {
        iconUrl: BlazedKid,
        theme: 'btn-back-red',
        name: 'Blazed Kid NFT',
        description: 'NFT Project',
        link: 'https://blazed-kid.netlify.app/',
    },
    {
        iconUrl: JDMLover,
        theme: 'btn-back-green',
        name: 'JDM Lover',
        description: 'Online Dealership For Japanese Domestic Market',
        link: 'https://jdmlover.netlify.app/',
    },
    {
        iconUrl: PreBuild,
        theme: 'btn-back-blue',
        name: 'PRE-BUILD PC',
        description: "Online High End Gaming PC's",
        link: 'https://pre-build.netlify.app/',
    },
    {
        iconUrl: shopify,
        theme: 'btn-back-pink',
        name: 'UNSANA',
        description: 'Online Shopify Store',
        link: 'https://www.unsana.shop/',
    },
    // {
    //     iconUrl: estate,
    //     theme: 'btn-back-black',
    //     name: 'Estelo',
    //     description: 'Bio',
    //     link: 'https://github.com/estelo2jz',
    // },
    // {
    //     iconUrl: summiz,
    //     theme: 'btn-back-yellow',
    //     name: 'Estelo',
    //     description: 'Bio',
    //     link: 'https://github.com/estelo2jz',
    // }
];