import React from 'react';

import BK from "../assets/BK.png";
import "../styles/HomeContent.scss";

const HomeContent = () => {
  return (
    <div className='home-content'>
      <h2>
        BLAZED KID
      </h2>
      <a href="https://opensea.io/collection/blazed-kid-nft" target='_blank' rel="noopener noreferrer">
        <div className='home-content__img-container'>
          <img src={BK} className='' alt="UNSANA" />
        </div>
      </a>
    </div>
  )
}

export default HomeContent;