// import React, { useRef } from 'react';
// import emailjs from '@emailjs/browser';

// const Msg = () => {
//   const form = useRef();

//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs.sendForm('service_qd4bhfr', 'template_l1sou4d', form.current, 'Kgi2m2l19tNE5lFSH')
//       .then((result) => {
//           console.log(result.text);
//       }, (error) => {
//           console.log(error.text);
//       });
//   };

//   return (
//     <form ref={form} onSubmit={sendEmail}>
//       <label>Name</label>
//       <input type="text" name="from_name" />
//       <label>Email</label>
//       <input type="email" name="from_email" />
//       <label>Message</label>
//       <textarea name="message" />
//       <input type="submit" value="Send" />
//     </form>
//   );
// };

// export default Msg;

import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import { useState } from "react";

const Msg = () => {
  const form = useRef();

  const [formValues, setFormValues] = useState({
    name: "",
    address: "",
    message: "",
  });
  const [records, setRecords] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    setRecords([...records, formValues]);
    setFormValues({
      name: "",
      address: "",
      message: "",
    });
    return this;
  };


  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_qd4bhfr', 'template_l1sou4d', form.current, 'Kgi2m2l19tNE5lFSH')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

  };

  return (
    <StyledContactForm>
      <div className='relative flex lg:flex-row flex-col max-container'>
      {/* <form ref={form} onSubmit={(e) => handleSubmit(e)  }> */}
      <form ref={form} onSubmit={(e) => {this.sendEmail(); this.handleSubmit(e)} } >
        <label>Name</label>
        <input 
          type="text" name="from_name"
          value={formValues.name}
          onChange={(e) =>
            setFormValues({ ...formValues, name: e.target.value })
          }
        />
        <label>Email</label>
        <input 
          type="email" name="from_email"
          // value={address}
          // onChange={(e) => setAddress(e.target.value)}
          value={formValues.address}
          onChange={(e) =>
            setFormValues({ ...formValues, address: e.target.value })
          }
        />
        <label>Message</label>
        <textarea name="message"
                    value={formValues.message}
                    onChange={(e) =>
                      setFormValues({ ...formValues, message: e.target.value })
                    }
        />
        <input type="submit" onSubmit={(e) => handleSubmit(e)} value="Send"  />
        {/* <button 
          type="submit" 
          onClick={() => setAddress("")} 
          // onClick={() => setAddress("")}
          // onClick={() => setAddress("")}
          value="Send"
        >
          Send
        </button> */}
        {/* <button type="submit">Save</button> */}
      </form>
      </div>
    </StyledContactForm>
  );
};

export default Msg;

// Styles
const StyledContactForm = styled.div`
  width: 400px;

  form {
    // position: relative;
    display: flex;
    // align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }

    label {
      margin-top: 1rem;
    }

    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: rgb(249, 105, 14);
      color: white;
      border: none;
    }
  }
`;