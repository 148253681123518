import React from "react";
import { NavLink, Routes,Route } from "react-router-dom";
import EMA  from "../assets/EMAA.png";
import GO from "../assets/NFT 1.png";


const Navbar = () => {
  return (

    <header className='header'>
      <NavLink to='/'>
        {/* <img src={EMA} alt='logo' className='w-18 h-18 object-contain' /> */}
        {/* <img src={GO} alt='logo' className='w-20 h-15 object-contain' /> */}
        <div className="header-navbar">
          <div className="menu-header">
            <img src={GO} className='w-10 h-10 object-contain' alt="logo" />
          </div>
        </div>
      </NavLink>
      <nav className='flex text-lg gap-7 font-medium'>
        <NavLink to='/about' className={({ isActive }) => isActive ? "text-blue-600" : "text-black" }>
          About
        </NavLink>
        <NavLink to='/projects' className={({ isActive }) => isActive ? "text-blue-600" : "text-black"}>
          Projects
        </NavLink>
      </nav>
    </header>
  );
};

export default Navbar;