import React, { Component } from 'react';
import { Link } from "react-router-dom";
import EMA from "../assets/EMAA.png";
import UNSANA1 from "../assets/UNSANA1.png";
import UNSANA2 from "../assets/UNSANA2.png";
import BK_LOGO from "../assets/BK_LOGO.png";
import UNSANA3 from "../assets/UNSANAFB1.png";
import UNSANA4 from "../assets/UNSANAFB2.jpg";

import HomeContent from './HomeContent';
import HomeContentV2 from './HomeContentV2';

import './../styles/HomeContent.scss';

export default class HomeTwo extends Component {
  render() {
    return (
      <div className="home-v2-container">
        <section className='home-v2-section'>
          <div className='home-v2-content'>
            <HomeContentV2 />
          </div>
          <div className='home-v2-logo'>
            <img src={BK_LOGO} alt='logo' className='w-20 h-15 object-contain' />
          </div>
        </section>
        <section className='home-v2-section'>
          <div className='home-v2-content'>
            <HomeContent />
          </div>
          <div className='home-v2-logo'>
            <img src={UNSANA1} alt='logo' className='w-20 h-15 object-contain' />
          </div>
        </section>

        {/* <section className='w-full h-screen relative'>
          <div className='absolute top-28 left-0 right-0 z-10 grid items-center justify-center'>
            <HomeContent />
          </div>
          <div className='absolute bottom-2 left-2'>
            <img src={UNSANA2} alt='logo' className='w-20 h-15 object-contain' />
          </div>
        </section> */}
      </div>
    )
  }
}